import type {
  TabListProps,
  TabPanelProps,
  TabProps,
  TabsProps,
} from "react-aria-components";

import {
  composeRenderProps,
  Tab as RACTab,
  TabList as RACTabList,
  TabPanel as RACTabPanel,
  Tabs as RACTabs,
} from "react-aria-components";
import { tv } from "tailwind-variants";

const tabsStyles = tv({
  base: "flex",
  variants: {
    orientation: {
      horizontal: "flex-col gap-lg",
      vertical: "",
    },
  },
});

export function Tabs(props: TabsProps) {
  return (
    <RACTabs
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabsStyles({ ...renderProps, className }),
      )}
    />
  );
}

const tabListStyles = tv({
  base: "flex",
  variants: {
    orientation: {
      horizontal: "flex-row gap-xs",
      vertical: "",
    },
  },
});

export function TabList<T extends object>(props: TabListProps<T>) {
  return (
    <RACTabList
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabListStyles({ ...renderProps, className }),
      )}
    />
  );
}

const tabProps = tv({
  base: "flex cursor-default items-center rounded-sm px-lg py-md text-sm font-semibold text-gray-500 outline-none outline-offset-0 transition hover:bg-brand-50 hover:text-brand-700 focus-visible:outline focus-visible:outline-4 focus-visible:outline-brand-600/25",
  variants: {
    isDisabled: {
      true: "",
    },
    isSelected: {
      false: "text-gray-500 hover:bg-brand-50 hover:text-brand-700",
      true: "bg-brand-50 text-brand-700",
    },
  },
});

export function Tab(props: TabProps) {
  return (
    <RACTab
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabProps({ ...renderProps, className }),
      )}
    />
  );
}

const tabPanelStyles = tv({
  base: "",
});

export function TabPanel(props: TabPanelProps) {
  return (
    <RACTabPanel
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tabPanelStyles({ ...renderProps, className }),
      )}
    />
  );
}
