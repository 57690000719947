import { Controller } from "react-hook-form";

import { Button } from "@/components/button";
import { Checkbox, CheckboxField } from "@/components/checkbox";
import {
  Description,
  FieldError,
  Input,
  Label,
  TextField,
} from "@/components/field";

import type { LoginFormData } from "./useLoginForm";

import { AuthContainer } from "../components/AuthContainer";
import { useLoginForm } from "./useLoginForm";
import { useLoginMutation } from "./useLoginMutation";

export function LoginForm() {
  const { control, handleSubmit } = useLoginForm();
  const { error, isError, isPending, mutate } = useLoginMutation();

  const onSubmit = (data: LoginFormData) => {
    mutate(data, {
      onError: (error) => {
        console.error("Login failed", error);
        // TODO: Implement error handling, e.g., display error message to user
      },
      onSuccess: (response) => {
        console.log("Login successful", response);
        window.location.href = "https://mb-dev.nlc.com/requests";
      },
    });
  };

  return (
    <AuthContainer
      description="Please enter your login details below"
      footer={
        <>
          {/* TODO: create a Link component if possible */}
          <a className="text-gray-500 underline underline-offset-2" href="/">
            Terms of use
          </a>
          <a className="text-gray-500 underline underline-offset-2" href="/">
            Privacy policy
          </a>
        </>
      }
      title="Platform login"
    >
      <form className="flex flex-col gap-3xl" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-xl">
          <Controller
            control={control}
            name="email"
            render={({
              field: { name, onBlur, onChange, ref, value },
              fieldState: { error, invalid },
            }) => (
              <TextField
                isInvalid={invalid}
                isRequired
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                validationBehavior="aria"
                value={value}
              >
                <Label>Email</Label>
                <Input placeholder="florence@nlc.com" ref={ref} />
                <FieldError>{error?.message}</FieldError>
              </TextField>
            )}
            rules={{ required: "Name is required." }}
          />

          <Controller
            control={control}
            name="password"
            render={({
              field: { name, onBlur, onChange, ref, value },
              fieldState: { error, invalid },
            }) => (
              <TextField
                isInvalid={invalid}
                isRequired
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                validationBehavior="aria"
                value={value}
              >
                <Label>Password</Label>
                <Input placeholder="••••••••" ref={ref} type="password" />
                <FieldError>{error?.message}</FieldError>
              </TextField>
            )}
            rules={{ required: "Name is required." }}
          />
        </div>
        <div className="flex justify-between">
          <CheckboxField className="max-w-sm">
            <Checkbox>Remember me</Checkbox>
            <Description>Save login for 30 days</Description>
          </CheckboxField>

          <a
            className="h-min text-sm font-semibold text-brand-700"
            href="/forgot-password"
          >
            Forgot password?
          </a>
        </div>
        <Button isDisabled={isPending} type="submit">
          {isPending ? "Signing in..." : "Sign in"}
        </Button>
        {/* TODO: update error handling on final implementation */}
        {isError && (
          <p className="text-red-500">
            {error.message || "An error occurred during login"}
          </p>
        )}
      </form>
    </AuthContainer>
  );
}
