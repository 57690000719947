import { DetailedForm } from "./DetailedForm";
import { useCreateRequestForm } from "./hooks/useCreateRequestForm";
import { MagicalBriefCreator } from "./MagicalBriefCreator";
import { SingleInputForm } from "./SingleInputForm";
import { TabsHeader } from "./TabsHeader";

export function BriefCreatorSection() {
  const {
    detailedMethods,
    filledMandatoryFields,
    formType,
    handleFormTypeChange,
    onSubmit,
    singleInputMethods,
    totalMandatoryFields,
  } = useCreateRequestForm();

  return (
    <div className="grid grid-cols-2 gap-2xl">
      <div className="flex flex-col gap-lg">
        <TabsHeader
          formType={formType}
          handleFormTypeChange={handleFormTypeChange}
        />

        {formType === "detailed" && <DetailedForm methods={detailedMethods} />}
        {formType === "single" && (
          <SingleInputForm methods={singleInputMethods} />
        )}
      </div>

      <MagicalBriefCreator
        filledMandatoryFields={filledMandatoryFields}
        formType={formType as "detailed" | "single"}
        onSubmit={onSubmit}
        totalMandatoryFields={totalMandatoryFields}
      />
    </div>
  );
}
