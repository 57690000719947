import { useMutation } from "@tanstack/react-query";

import apiClient from "@/lib/api/client";

import type { LoginFormData } from "./useLoginForm";

export function useLoginMutation() {
  return useMutation({
    mutationFn: async ({ email, password }: LoginFormData) => {
      const response = await apiClient
        .url("/auth/login")
        .post({ email, password })
        .res();

      return response.data;
    },
  });
}
