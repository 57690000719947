import type { UseFormReturn } from "react-hook-form";

import { useIsMutating } from "@tanstack/react-query";
import { FormProvider } from "react-hook-form";

import { ControlledTiptapEditor } from "@/components/ui/controlled/ControlledTiptapEditor";

import type { SingleInputFormData } from "./schemas";

interface SingleInputFormProps {
  methods: UseFormReturn<SingleInputFormData>;
}

export function SingleInputForm({ methods }: SingleInputFormProps) {
  const isPending = Boolean(
    useIsMutating({
      mutationKey: ["createOutputEnhancement"], // TODO: find scalable solution for handling query keys
      status: "pending",
    }),
  );

  return (
    <FormProvider {...methods}>
      <ControlledTiptapEditor
        className="max-h-[124px]"
        control={methods.control}
        isDisabled={isPending}
        isRequired
        label="📄 Request Description"
        name="requestDescriptionText"
        onChangeHTML={(html) =>
          methods.setValue("requestDescriptionHTML", html)
        }
        onChangeText={(text) =>
          methods.setValue("requestDescriptionText", text)
        }
        placeholder="Tell us about your request. The more specific you are, the better the end result(s) will be. "
        tooltipProps={{
          children:
            "Describe the overall purpose and specific requirements of your design request. Include any critical project details that will guide the creation process.",
        }}
      />
      <ControlledTiptapEditor
        className="max-h-[124px]"
        control={methods.control}
        isDisabled={isPending}
        isRequired
        label="💬 Texts for Design"
        name="textsForDesignText"
        onChangeHTML={(html) => methods.setValue("textsForDesignHTML", html)}
        onChangeText={(text) => methods.setValue("textsForDesignText", text)}
        placeholder="Tell us what text should be displayed on your design. "
        tooltipProps={{
          children:
            "Enter the exact text, key messages, and calls to action that should be featured on the design. Specify any slogans or phrases to ensure they align with your communication goals.",
        }}
      />
    </FormProvider>
  );
}
