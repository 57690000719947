import type { Key } from "react-aria-components";

import { Tab, TabList, Tabs } from "@/components/ui/Tabs";

type TabsHeaderProps = {
  formType: Key;
  handleFormTypeChange: (key: Key) => void;
};

export function TabsHeader({
  formType,
  handleFormTypeChange,
}: TabsHeaderProps) {
  return (
    <div className="flex w-full items-center justify-between">
      <span className="text-lg font-semibold text-gray-700">Brief Creator</span>
      <Tabs onSelectionChange={handleFormTypeChange} selectedKey={formType}>
        <TabList aria-label="Brief creator type">
          <Tab id="detailed">Detailed Form</Tab>
          <Tab id="single">Single Input</Tab>
        </TabList>
      </Tabs>
    </div>
  );
}
