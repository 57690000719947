import { Separator } from "react-aria-components";

import { BriefCreatorSection } from "./brief-creator-section";
import { Header } from "./Header";
import { IntroSection } from "./intro-section";
import { TechnicalDetailsSection } from "./technical-details-section";

export function CreateRequestForm() {
  return (
    <div>
      <div className="mx-auto w-[1156px] rounded-xl bg-white">
        <Header />

        <div className="flex flex-col gap-3xl px-3xl py-2xl">
          <IntroSection />
          <Separator />
          <BriefCreatorSection />
          <Separator />
          <TechnicalDetailsSection />
        </div>
      </div>
    </div>
  );
}
