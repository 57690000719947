import clsx from "clsx";
import { Loader } from "lucide-react";
import { useCallback } from "react";

import { Button } from "@/components/button";
import { TiptapEditor } from "@/components/ui/TiptapEditor";

import type { DetailedFormData, MappedSingleInputFormData } from "./schemas";

import SparklesIcon from "../../assets/sparkles.svg?react";
import WandIcon from "../../assets/wand.svg?react";
import useAIStream from "./hooks/useAIStream";

function PendingState() {
  return (
    <div className="flex h-min flex-col gap-lg rounded-2xl border border-gray-200 bg-gray-50 px-7xl py-4xl text-center">
      <div className="flex flex-col items-center gap-lg">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-brand-600" />
          <span className="text-lg font-semibold text-gray-700">
            Magical Brief Creator
          </span>
        </div>
        <span className="text-sm font-medium text-gray-700">
          Enter your brief details on the left, and enhance or just preview the
          final results here.
        </span>
      </div>

      <div className="flex flex-col items-center gap-md">
        <Button isCustomLoading isDisabled isLoading>
          <Loader className="animate-spin" />
          Improving...
        </Button>
        <span className="text-sm text-gray-700">
          Enhancements in progress...
        </span>
      </div>
    </div>
  );
}

type SuccessStateProps = {
  formType: "detailed" | "single";
  handleClearResponse: () => void;
  isPending: boolean;
  onSubmit: () => void;
  streamedResponse: string;
};

function SuccessState({
  formType,
  handleClearResponse,
  isPending,
  onSubmit,
  streamedResponse,
}: SuccessStateProps) {
  return (
    <div className="flex h-min flex-col gap-lg rounded-2xl border border-gray-200 bg-gray-50 px-xl pb-xl pt-lg text-center">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-brand-600" />
          <span className="text-lg font-semibold text-gray-700">
            Magical Brief Creator
          </span>
        </div>
        <div className="flex items-center gap-sm">
          <Button
            className="w-auto"
            color="gray"
            onPress={handleClearResponse}
            size="sm"
            variant="tertiary"
          >
            Discard
          </Button>
          <Button
            className="w-auto"
            isDisabled={isPending}
            onPress={onSubmit}
            size="sm"
          >
            <WandIcon />
            Regenerate
          </Button>
        </div>
      </div>
      <div className="flex flex-col text-left">
        <span className="text-md font-medium text-gray-700">Final Brief</span>
        <TiptapEditor
          className={clsx({
            "h-[684px]": formType === "single",
            "h-[758px]": formType === "detailed",
          })}
          isDisabled={isPending}
          value={streamedResponse}
        />
      </div>
    </div>
  );
}

type EmptyStateProps = {
  filledMandatoryFields: number;
  isPending: boolean;
  onSubmit: () => void;
  totalMandatoryFields: number;
};

function EmptyState({
  filledMandatoryFields,
  isPending,
  onSubmit,
  totalMandatoryFields,
}: EmptyStateProps) {
  return (
    <div className="flex h-min flex-col gap-lg rounded-2xl border border-gray-200 bg-gray-50 px-7xl py-4xl text-center">
      <div className="flex flex-col items-center gap-lg">
        <div className="flex items-center gap-xs">
          <SparklesIcon className="text-brand-600" />
          <span className="text-lg font-semibold text-gray-700">
            Magical Brief Creator
          </span>
        </div>
        <span className="text-sm font-medium text-gray-700">
          Enter your brief details on the left, and enhance or just preview the
          final results here.
        </span>
      </div>

      <div className="flex flex-col items-center gap-md">
        <Button
          isCustomLoading
          isDisabled={
            isPending || filledMandatoryFields !== totalMandatoryFields
          }
          isLoading={isPending}
          loadingLabel="Deploying"
          onPress={onSubmit}
        >
          {isPending ? (
            <>
              <Loader className="animate-spin" />
              Improving...
            </>
          ) : (
            <>
              <WandIcon />
              Improve Brief
            </>
          )}
        </Button>
        <span className="text-sm text-gray-700">
          {`${filledMandatoryFields}/${totalMandatoryFields} mandatory fields filled`}
        </span>
      </div>
    </div>
  );
}

type MagicalBriefCreatorProps = {
  filledMandatoryFields: number;
  formType: "detailed" | "single";
  onSubmit: () => Promise<{
    data: DetailedFormData | MappedSingleInputFormData;
    formType: "detailed" | "single";
  } | null>;
  totalMandatoryFields: number;
};

export function MagicalBriefCreator({
  filledMandatoryFields,
  formType,
  onSubmit,
  totalMandatoryFields,
}: MagicalBriefCreatorProps) {
  const {
    abortStream,
    isPending,
    resetStreamedResponse,
    sendPrompt,
    streamedResponse,
  } = useAIStream();

  const handleSubmit = useCallback(async () => {
    const result = await onSubmit();

    if (result) {
      const { data, formType } = result;

      sendPrompt(data, formType);
    }
  }, [onSubmit, sendPrompt]);

  const handleClearResponse = useCallback(() => {
    if (isPending) {
      abortStream();
    } else {
      resetStreamedResponse();
    }
  }, [isPending, abortStream, resetStreamedResponse]);

  const isFetching = isPending && !streamedResponse;
  const isSuccess = streamedResponse;
  const isEmpty = !isPending && !streamedResponse;

  if (isEmpty) {
    return (
      <EmptyState
        filledMandatoryFields={filledMandatoryFields}
        isPending={isPending}
        onSubmit={handleSubmit}
        totalMandatoryFields={totalMandatoryFields}
      />
    );
  }

  if (isFetching) {
    return <PendingState />;
  }

  if (isSuccess) {
    return (
      <SuccessState
        formType={formType}
        handleClearResponse={handleClearResponse}
        isPending={isPending}
        onSubmit={handleSubmit}
        streamedResponse={streamedResponse}
      />
    );
  }

  return null;
}
